.ffncc-events-list__container {
    margin-top: 2rem;
    padding-bottom: 4rem;

    .ffncc-events-list__create-button {
        @include ffncc-icon-button();

        margin-bottom: 1rem;
    }
}

.ffncc-events-list__event-controls {
    display: flex;

    button + button {
        margin-left: .5rem;
    }
}

.ffncc-events-list__list-header {
    display: flex;
    justify-content: space-between;
}

.ffncc-events-list__no-events-container {
    color: $ffncc-faded-text;
    margin-top: 4rem;
    text-align: center;
}