.ffncc-calendar-event__container {
    align-items: center;
    display: flex;
    position: relative;

    .ffncc-calendar-event__title {
        flex: 1;
        font-size: $ffncc-font-size-smaller;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}