.ffncc-calendar-page__container {
    margin-bottom: 2rem;
}

.ffncc-calendar-page__legend-container {
    padding: 1.5rem 0;

    .ffncc-calendar-page__legend-title {
        @include ffncc-section-header();
    }
}

.ffncc-calendar-page__legend {
    border: 1px solid $ffncc-border-color;
    display: flex;
    justify-content: space-evenly;

    .ffncc-calendar-page__legend-item {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: .5rem;
    }

    .ffncc-calendar-page__legend-item + .ffncc-calendar-page__legend-item {
        border-left: 1px solid $ffncc-border-color;
    }

    .ffncc-calendar-page__legend-label {
        margin-top: .5rem;
    }
}

.rbc-event.ffncc-cape-ann-finns {
    background-color: $ffncc-cape-ann-finns-color;
}

.rbc-event.ffncc-finnish-american-society-of-cape-cod {
    background-color: $ffncc-finnish-american-society-of-cape-cod-color;
}

.rbc-event.ffncc-finnish-center-at-saima-park {
    background-color: $ffncc-finnish-center-at-saima-park-color;
}

.rbc-event.ffncc-finnish-heritage-society-sovittaja {
    background-color: $ffncc-finnish-heritage-society-sovittaja-color;
}

.rbc-event.ffncc-finlandia-foundation-boston {
    background-color: $ffncc-finlandia-foundation-boston-color;
}

.rbc-event.ffncc-finnish-heritage-house {
    background-color: $ffncc-finnish-heritage-house-color;
}

.rbc-event.ffncc-finnish-american-heritage-society-of-canterbury-ct {
    background-color: $ffncc-finnish-american-heritage-society-of-canterbury-ct-color;
}

.ffncc-calendar-page__legend-badge {
    background-color: blue;
    color: white;
    border-radius: .25rem;
    padding-left: .2rem;
    width: 100%;
    height: 21px;

    &.ffncc-cape-ann-finns {
        background-color: $ffncc-cape-ann-finns-color;
    }

    &.ffncc-finnish-american-society-of-cape-cod {
        background-color: $ffncc-finnish-american-society-of-cape-cod-color;
    }

    &.ffncc-finnish-center-at-saima-park {
        background-color: $ffncc-finnish-center-at-saima-park-color;
    }

    &.ffncc-finnish-heritage-society-sovittaja {
        background-color: $ffncc-finnish-heritage-society-sovittaja-color;
    }

    &.ffncc-finlandia-foundation-boston {
        background-color: $ffncc-finlandia-foundation-boston-color;
    }

    &.ffncc-finnish-heritage-house {
        background-color: $ffncc-finnish-heritage-house-color;
    }

    &.ffncc-finnish-american-heritage-society-of-canterbury-ct {
        background-color: $ffncc-finnish-american-heritage-society-of-canterbury-ct-color;
    }
}