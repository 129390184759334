// MONTSERRAT
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
  
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}
  
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}


// TIMES NEW ROMAN
@font-face {
    font-family: "Times New Roman";
    src: url("../fonts/Times New Roman/Times New Roman.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
  
@font-face {
    font-family: "Times New Roman";
    src: url("../fonts/Times New Roman/Times New Roman Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}
  
@font-face {
    font-family: "Times New Roman";
    src: url("../fonts/Times New Roman/Times New Roman Bold.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Times New Roman";
    src: url("../fonts/Times New Roman/Times New Roman Bold Italic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}


// COOPER
@font-face {
    font-family: "Cooper BT";
    src: url("../fonts/Cooper/Cooper\ Medium\ BT.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Cooper BT";
    src: url("../fonts/Cooper/Cooper\ Medium\ Italic\ BT.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Cooper BT";
    src: url("../fonts/Cooper/Cooper\ Bold\ BT.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Cooper BT";
    src: url("../fonts/Cooper/Cooper\ Bold\ Italic\ BT.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}