.ffncc-reset-password__container {
    margin-bottom: 4rem;
    margin-top: 4rem;
    max-width: 400px;

    form {
        margin-top: 1rem;
    }
}

.ffncc-reset-password__login {
    margin-top: 1rem;
}