@mixin ffncc-section-header() {
    font-size: $ffncc-font-size-larger;
    font-weight: 300;
    margin-bottom: .5rem;
}

@mixin ffncc-icon-button() {
    align-items: center;
    display: flex;

    svg {
        margin-right: .5rem;
    }
}

@mixin ffncc-contain() {
    object-fit: contain;
}

@mixin ffncc-cover() {
    object-fit: cover;
}