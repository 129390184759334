.ffncc-loading-state__overlay {
    background: white;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
}

.ffncc-loading-state__spinner-container {
    align-items: center;
    color: $ffncc-finlandia-blue;
    display: flex;
    height: 400px;
    justify-content: center;
    max-height: 100vh;
}