.ffncc-login__container {
    margin-bottom: 4rem;
    margin-top: 4rem;
    max-width: 400px;

    form {
        margin-top: 1rem;
    }
}

.ffncc-login__reset-password {
    margin-top: 1rem;
}