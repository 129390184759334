body .App {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;

    h1 {
        font-size: $ffncc-font-size-header;
        margin-bottom: 1rem;
    }
}

.App {
    min-height: 100vh;
}

main {
    position: relative;
}

.ql-snow .ql-picker.ql-font {
    width: 150px;
}

.ql-picker.ql-font {
    .ql-picker-item {
        font-size: 0;
        &:before {
            content: attr(data-value) !important;
            font-size: 14px;
        }
    }
}

.ql-picker.ql-font {
    .ql-active {
        &:before {
            content: attr(data-value) !important;
            font-size: 14px;
        }
    }
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
    font-family: "Montserrat", cursive;
    content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="TimesNewRoman"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="TimesNewRoman"]::before {
    font-family: "Times New Roman", cursive;
    content: "Times New Roman" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="CooperBT"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="CooperBT"]::before {
    font-family: "Cooper BT", sans-serif;
    content: "Cooper BT" !important;
}

/* Set content font-families */
.ql-font-Montserrat {
    font-family: "Montserrat";
}
.ql-font-TimesNewRoman {
    font-family: "Times New Roman";
}
.ql-font-CooperBT {
    font-family: "Cooper BT";
}