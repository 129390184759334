.ffncc-view-event__event-error {
    text-align: center;
}

.ffncc-view-event__container {
    .ffncc-view-event__breadcrumb-container {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    ol.breadcrumb {
        background: white;
        margin-bottom: 0;        
    }    
}

.ffncc-view-event__event-container {
    .ffncc-view-event__event-content {
        padding-top: 1.5rem;
        margin-bottom: 4rem;

        > * + * {
            margin-top: 1rem;
        }

        > div {
            width: fit-content;
        }

        svg {
            margin-right: .5rem;
        }
    }

    .ffncc-view-event__sub-title {
        font-size: $ffncc-font-size-larger;
        font-weight: 500;
    }

    .ffncc-view-event__organization-content {
        display: flex;
    }

    .ffncc-view-event__organization-logo {
        height: 72px;
        object-fit: contain;
        margin-right: 1rem;
        width: 72px;
    }
    
    .ffncc-view-event__event-icon-item {
        align-items: center;
        display: flex
    }

    .ffncc-view-event__event-icon-label {
        margin-right: .5rem;
        font-weight: 500;

        svg {
            margin-bottom: 4px;
        }
    }

    .ffncc-view-event__event-descriptive-content {
        margin-top: 1.5rem;

        .ql-container {
            font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 16px;
            padding: 0;
        }
    }
}

.ffncc-view-event__hero-container {
    img {
        height: 400px;
        object-fit: cover;
        width: 100%;
    }

    img.ffncc-contain {
        @include ffncc-contain();

        background: $ffncc-background-color;
        border: 1px solid $ffncc-border-color;
        border-width: 1px 0;
    }

    img.ffncc-cover {
        @include ffncc-cover();
    }
}