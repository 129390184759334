.nav-bar {
    border-bottom: 1px solid $ffncc-border-color;
}

.nav-bar__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;

    button {
        height: 40px;
    }
}

.nav-bar__ffncc_logo {
    align-items: center;
    display: flex;
    font-size: $ffncc-font-size-large;
    font-weight: 500;

    img {
        height: 60px;
        margin-right: 1rem;
        width: 60px;
    }
}

.ffncc-dev-mode-indicator {
    color: red;
    font-weight: 500;
    padding: 0 2rem;
}