.ffncc-agenda-event__link {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .ffncc-update-alert-icon {
        margin-right: .5rem;
    }

    .ffncc-agenda-event__title-container {
        align-items: center;
        display: flex;
    }
}

.rbc-agenda-event-cell {
    .badge.ffncc-cape-ann-finns {
        background-color: $ffncc-cape-ann-finns-color;
    }

    .badge.ffncc-finnish-american-society-of-cape-cod {
        background-color: $ffncc-finnish-american-society-of-cape-cod-color;
    }

    .badge.ffncc-finnish-center-at-saima-park {
        background-color: $ffncc-finnish-center-at-saima-park-color;
    }
    
    .badge.ffncc-finnish-heritage-society-sovittaja {
        background-color: $ffncc-finnish-heritage-society-sovittaja-color;
    }

    .badge.ffncc-finlandia-foundation-boston {
        background-color: $ffncc-finlandia-foundation-boston-color;
    }
}