.ffncc-edit-event__breadcrumbs-container {
    border-bottom: 1px solid $ffncc-border-color;
        
    ol.breadcrumb {
        background: white;
        margin-bottom: 0;        
    }   
}

.ffncc-edit-event__container {
    margin-bottom: 4rem;
    margin-top: 2rem;

    .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ql-editor {
        min-height: 200px;
    }

    .ql-container {
        font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 16px;
    }

    label {
        font-weight: 500;
    }

    .ffncc-edit-event__notice-text {
        font-size: $ffncc-font-size-smaller;
        font-style: italic;
    }
}

.ffncc-edit-event__feature-image-container {
    .ffncc-edit-event__feature-image {
        background: $ffncc-border-color;
        height: 200px;
        margin-bottom: 1rem;
        width: 100%;
    }

    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    img.ffncc-contain {
        @include ffncc-contain();
    }

    img.ffncc-cover {
        @include ffncc-cover();
    }
}

.ffncc-disabled-time .rdtTimeToggle {
    display: none;
}
